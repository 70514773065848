export function upgrade(state, building) {
  const newState = { ...state };
  const { resources, buildings } = newState;

  const { level, upgrade, production } = building;
  const { cost, productionChange, costChange, bonusChange } = upgrade;

  const isBuild = building.level === 0;

  newState.resources = resources.map(resource => {
    const costToApply = cost[resource.id] && cost[resource.id];

    if (costToApply) {
      resource.amount = resource.amount - costToApply;
    }

    return resource;
  });

  const upgradedBuilding = {
    ...building,
    level: level + 1,
    upgrade: {
      ...upgrade,
      cost: {
        minerals: cost.minerals * costChange,
        energy: cost.energy * costChange
      }
    }
  };

  if (!isBuild) {
    const isBonusBuilding = building.bonuses && !!building.bonuses[0];
    const hasBonusUpgrade = bonusChange && bonusChange > 0;
    if (isBonusBuilding && hasBonusUpgrade) {
      upgradedBuilding.bonuses = building.bonuses.map(bonus => {
        return {
          ...bonus,
          amount: bonus.amount * (1 + bonusChange)
        };
      });
    }

    const isProductionBuilding = building.production;
    if (isProductionBuilding && productionChange) {
      upgradedBuilding.production = production * productionChange;
    }
  }

  newState.buildings = buildings.map(existingBuilding => {
    if (upgradedBuilding.id === existingBuilding.id) {
      return upgradedBuilding;
    }

    return existingBuilding;
  });

  return newState;
}
