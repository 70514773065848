<script>
  import buildingsSpecs from "../specifications/buildings.json";
  import resourcesSpecs from "../specifications/resources.json";

  import Building from "./Building.svelte";
  import Resource from "./Resource.svelte";

  import { applyActions } from "../modifications";
  import { calculateProduction } from "../resources";
  import { upgrade as upgradeBuilding } from "../building";

  let state = {
    buildings: buildingsSpecs.map(building => ({
      ...building,
      level: building.level || 0
    })),
    resources: resourcesSpecs.map(resource => ({
      ...resource,
      amount: 0
    }))
  };

  setInterval(() => {
    state = calculateProduction(state);
  }, 5000);

  $: buildings = applyActions(state, {
    target: "buildings",
    actions: {
      upgrade: building => (state = upgradeBuilding(state, building))
    }
  });

  $: resources = state.resources.map(resource => ({
    ...resource,
    amount: Math.round(resource.amount)
  }));
</script>

<main>
  <h1>N</h1>

  <h3>Resources</h3>
  <div class="icon-panel">
    {#each resources as resource}
    <Resource {...resource}></Resource>
    {/each}
  </div>

  <h3>Buildings</h3>
  <div class="icon-panel">
    {#each buildings as building}
    <Building {...building}></Building>
    {/each}
  </div>

  <footer>
    <p><strong>N</strong> - space-themed, pve, strategy game.</p>
    <p>
      Icons by Delapouite, Faithtoken, and Lorc from
      <a href="https://game-icons.net" rel="noopener noreferrer nofollow"
        >game-icons.net</a
      >.
    </p>
    <p>Thank you for playing.</p>
  </footer>
</main>

<style>
  main {
    text-align: center;
    padding: 1em;
    margin: 0 auto;
  }

  h3 {
    text-align: left;
  }

  .icon-panel {
    display: flex;
    margin: 10px auto;
    padding: 10px 0;
  }

  footer {
    position: fixed;
    bottom: 0;

    text-align: left;

    padding: 10px 0;
  }

  footer h5 {
    margin: auto;
  }

  footer p {
    font-size: 12px;
    margin: 2px auto;
  }
</style>
