import { getBonuses } from "./modifications";

export function calculateProduction(state) {
  const newState = { ...state };
  const { resources, buildings } = newState;
  const existingBuildings = buildings.filter(building => building.level > 0);

  const bonuses = getBonuses(existingBuildings, { type: "production" });

  newState.resources = resources.map(resource => {
    const resourceBuilding = existingBuildings.find(
      building => building.resource === resource.id
    );
    const bonusModifier = bonuses.reduce(
      (sum, { resources: bonusResources, amount }) => {
        const isBonusResource = bonusResources.includes(resource.id);
        return isBonusResource ? sum + amount : sum;
      },
      1
    );

    if (resourceBuilding) {
      const { amount: currentAmount } = resource;
      const { production } = resourceBuilding;

      return {
        ...resource,
        amount: currentAmount + production * bonusModifier
      };
    }

    return resource;
  });

  return newState;
}
