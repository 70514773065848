<script>
  export let name;
  export let amount;
  export let iconFilename;

  const iconSrc = `/assets/${iconFilename}`;
</script>

<div>
  <img src="{iconSrc}" alt="{name} icon" />
  <span>{ name }</span>
  <span>{ amount }</span>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;

    margin-right: 20px;
  }

  img {
    height: 30px;
    width: auto;
    margin-bottom: 5px;
  }
</style>
