<script>
  export let name;
  export let level;
  export let iconFilename;
  export let upgrade;

  const iconSrc = `/assets/${iconFilename}`;

  const handleUpgrade = () => upgrade && upgrade();
</script>

<div>
  <img src="{iconSrc}" alt="{name} icon" />
  <span>{ name }</span>
  <span>{ level }</span>
  <button on:click="{handleUpgrade}" class:inactive="{!upgrade}">
    {level === 0 ? "Build" : "Upgrade"} <img src="/assets/upgrade.svg" alt="" />
  </button>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;

    margin-right: 20px;
  }

  img {
    height: 30px;
    width: auto;
    margin-bottom: 5px;
  }

  button img {
    height: 15px;
    margin: auto auto auto 5px;
    vertical-align: bottom;
  }
</style>
