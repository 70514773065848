export function applyActions(state, { actions, target }) {
  const newState = { ...state };
  const modItems = newState[target];

  const { resources } = newState;
  const { upgrade } = actions;

  return modItems.map(item => {
    const isUpgradeable =
      item.hasOwnProperty("upgrade") &&
      Object.entries(item.upgrade.cost).every(([resourceId, cost]) => {
        const { amount } = resources.find(
          resource => resource.id === resourceId
        );

        return amount >= cost;
      });

    return {
      ...item,
      upgrade: isUpgradeable ? () => upgrade(item) : null
    };
  });
}

export function getBonuses(items, { type }) {
  return items.reduce((extractedBonuses, { bonuses }) => {
    const relevantBonuses =
      bonuses &&
      bonuses.filter(bonus => {
        return bonus.type === type;
      });

    const hasRelevantBonuses = relevantBonuses && relevantBonuses[0];
    return hasRelevantBonuses
      ? extractedBonuses.concat(relevantBonuses)
      : extractedBonuses;
  }, []);
}
